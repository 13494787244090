.Page {
  padding-top: 8%;
}
#Greeting {
  font-size: 2.7rem;
  padding-top:200px
}

#GreetingCompound {
  display: flex;
  justify-content: space-evenly;
  height: 95vh;
  
}

#GreetingText {
  margin: 0px;
}

#GreetingSubText {
  font-size: 34px;
}

#GreetingPicDiv {
  width:50vw;
  height:700px;
  display: flex;

}

#GreetingPic {
  border: 3px solid #f07178;
  border-radius: 10px;
  filter: drop-shadow(0px 52px 74px rgba(0, 21, 64, 0.138559));
}
.question {
  height: 30vh!important;
  width: 20%;
  margin-top: 200px;
  margin-left: auto;
  margin-right: 14%;
  border-radius: 20px;
  background-color: rgb(36, 36, 36);
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  box-sizing: border-box;
  color: #f07178;
  cursor: pointer;
  text-decoration: none;
  display: block;
  transition: all 0.4s ease-in-out;
  padding: 1em 1em 1em;
  float:left;
  border-style: solid;
  border-color: #f07178;
  height: 10vh!important;
  width: 10vh;
  border-radius: 50px;
  cursor: pointer;
  border-width: thin
}
.unshrink{
  height: 30vh!important;
  width: 17%;
  cursor:auto;
  /* //border-color: transparent; */
}
.unhide{
  /* transition: all 1s ease-in-out!important; */
  visibility: visible !important;
  transition-delay:0.4s!important


}
.wraptext{
  transition-delay:0s;
  visibility: hidden;

}
.number{
  display:none;
  visibility: hidden;
}
.numbershow{
  visibility: visible !important;
  display: block!important;
  position: relative!important;
  top: -10%;
  left: 32%;
}
.cicle{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #f07178;
  display: block;
  /* animation-name: scale-up-blob ;
  transition: all 1s ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 2s; */
}
select{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New'!important
}
input{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New'!important
}

/* Style inputs with type="text", select elements and textareas */
input[type=text], select, textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 0px solid #ccc; /* Gray border */
  border-bottom: 1px solid #f07178;
  color: #ccc;
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
  background-color: transparent;
  outline: none;
}
option { 
  /* Whatever color  you want */
  background-color: rgb(136, 136, 136);
  color: white;
  border: 0px;
}
/* Style the submit button with a specific background color etc */
input[type=submit] {
  background-color: #f07178;
  margin-left: auto;
  margin-right: auto;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
  background-color: #ccc;
}

/* Add a background color and some padding around the form */
.container {
  padding: 20px;
  padding-top: 0px!important;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccc;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #ccc;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
  /* background: black; */
 -webkit-box-shadow: 0 0 0 30px black  inset !important;
 -webkit-text-fill-color: #ccc !important;
}
#special{
  padding: 0%;
  overflow: hidden;
}
#wrapper{
  width:100%;
  height: 100%;
  
}
#left{    
  float:left; 
  width:50%;
  height: 100%;
  background-color: 	#FC766A;
  padding:2rem;
  box-sizing: border-box;

}
#right{
  width:50%;
  height: 100%;
  padding:2rem;
  box-sizing: border-box;
  background-color: 	whitesmoke;
  float: left;
}
#con{
  width: 100%!important;
  }
#tt{
  padding-bottom: 1rem!important;
}
#pc{
  padding-bottom: 4rem!important;
}
#wrapper { display: flex; }
.icon{
  width:10%;
  margin-bottom:1.7rem;
  margin-right:10% ;
  float: left
}
.lbl{
  width:80%;
  height:2rem;
  float: left;
  margin-bottom:1.7rem
}