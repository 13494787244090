#canvas2 {
    display: inline;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    max-height: 50vh;
    max-width: 75vh;
    position:  absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

}
#canvasDiv{
    float:left;
    width:40%;
    max-width: 70vh;
    height: 50vh!important;
    padding-right: 10%;

    background-color: rgb(242,231,191);
    margin-top: 3vh;
    margin-bottom: 0%;
    border-radius: 1em;
    height:0;
    position: relative;
}
.playgrounddiv{
    width: 100%;

}
#model-selector{
    width: auto;
    height:40vh
}
#controls{
    float:right;
    margin-top: 3vh;
    display :block;
    box-sizing: border-box;
    width:50%;
    padding-left:12rem;

}
.fields{
    margin-bottom: 5%;
}
.btn{
    width: 25%;
    margin-right: 5%;
    height: 45px;
    border-radius: 5%;
    border: 0px;
    background-color: #FC766A;
    color: #ccc;
    cursor: pointer;
}
.btn{
    width: 25%;
    margin-right: 5%;
    height: 45px;
    border-radius: 5%;
    border: 0px;
    background-color: #FC766A;
    color: #ccc;
    cursor: pointer;
}
.false{    -webkit-text-size-adjust: 100%;
    
    font-size: 1.4vh;
    line-height: 120%;
    box-sizing: border-box;
    transition: all .1s ease;
    color: #ccc;
    display: inline-block;
    padding: 1.5vh;
    width: 11rem;
    height: 17vh;
    border-radius: 1EM;
    margin: .5vh;
    margin-right: 2vh;
    margin-top: 2vh;
    text-align: center;
    background-color: #101820;
    float: left;

    cursor:pointer
}
.false:hover{
    transform: scale(1.05);
    transition: all .5s ease;
}
.true{
    -webkit-text-size-adjust: 100%;
    
    font-size: 1.4vh;
    line-height: 120%;
    box-sizing: border-box;
    transition: all .1s ease;
    color: #ccc;
    display: inline-block;
    padding: 1.3vh;
    width: 11rem;
    height: 17vh;
    border-radius: 1EM;
    margin: .5vh;
    margin-right: 2vh;
    margin-top: 2vh;
    text-align: center;
    background-color: #101820;
    float: left;
    border: .4vh solid #FC766A;
    cursor:pointer;
    transform: scale(1.1);

    
}

.img{
    -webkit-text-size-adjust: 100%;
    
    font-size: 1.4vh;
    line-height: 120%;
    color: #000;
    text-align: center;
    box-sizing: border-box;
    border: 0;
    vertical-align: middle;
    transition: all .1s ease;
    width: 8vh;
}
#brushes{
    -webkit-text-size-adjust: 100%;
    
    color: #333;
    line-height: 100%;
    box-sizing: border-box;
    transition: all .1s ease;
    font-size: 2vh;
}
#brushes div{-webkit-text-size-adjust: 100%;

color: #333;
line-height: 120%;
font-size: 2vh;
box-sizing: border-box;
transition: all .1s ease;
display: inline-block;
vertical-align: middle;
margin-right: 1vw;

}

#brushes label{-webkit-text-size-adjust: 100%;

line-height: 120%;
font-size: 2vh;
display: inline-block;
max-width: 100%;
font-weight: 700;
transition: all .1s ease;
box-sizing: border-box;
color: #fff;
width: 4vh;
height: 4vh;
margin: 1vh;
border-radius: 50%;
border: .2vh solid transparent;
cursor:pointer;
padding:1.2vh;
}
#brushes div #br-1~label {
    background-color: blue;
}
#brushes div #br-2~label {
    background-color: red;
}
#brushes div #br--3~label {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAN1wAADdcBQiibeAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAHfSURBVHic7ds9bgJBDIZhJ23IIeESKZMyt4oUQDmWU4AlhIZlfmyPPfpeidrwaBDa9UKEEEIIIYQQQgghhBaLifZMtHOct2Oivdc805joi4mYic5M9O4w742Jfq4zv63nmXaDxx6Id3icGrGAZ4r4AC8n4gaeCeITvFyIFXiqiJV4ORAb8FQQG/FiI3bgDSF24sVEHMDrQhzEi4WogNeEqIQXA1ERrwpRGW8uIhMdlD+IvI5cuOzjy+XZ0WjmYQagxWkonkTjWaetU58ecVk8J8Q/JvpdFk8yRlwbT0qEGA9PSoAYF08KjBgfTwqImAdPCoSYD08KgJgXT5qImB9PmoC4Dp7kiLgennRFtLo0Y75c+rnivXoOc+hl9hswy/Er7PIEhGsTfkTWQZyAtw7iRLz8iAHw8iIGwsuHGBAvD2JgvPiICfDiIibCi4dojGe51pyPaIx3YvvF+jxEDzynWXMQGQ8XjcdEH5YnrzDP4iR+epqVPpQWYtWdZGXEuXiSAmLTbXglxBh40gBi1w5jEDEWntSBOLQA6kSMiSc1IKpszxoRY+NJFYiqq8dKxBx40gaiyd72CWIuPKmAaLr0foCYE0+6QXR5YuAOMTeexPjLP0IIIYQQQgghhBBS6R/rQIQOR9dRzwAAAABJRU5ErkJggg==) no-repeat 50%;
    background-size: cover;
}
#brushes div input:checked~label {
    border: 0.4vh solid white;
}
#brushes div #br-2~label:hover {
    background-color: #fd7070;
}
#brushes div #br-1~label:hover {
    background-color: #70edfd;
}
#brushes div #br--3~label:hover {
    border: 0.4vh solid white;
    padding: 0.8vh;
}
#brushes input{-webkit-text-size-adjust: 100%;

font: inherit;
color: inherit;
font-family: inherit;
font-size: inherit;
transition: all .1s ease;
box-sizing: border-box;
padding: 0;
margin: 4px 0 0;
line-height: normal;
display: none;
}
#clearAll{
    margin: 0;

    height: 4vh;
    box-sizing: border-box;
    width: 10vh;
    padding: 1.2vh;
    background-color: #fff;
    color: #FC766A;
    border-radius: 1.3em;
    border:0;
    font-weight: 600;
}
#clearAll:hover{
    
        color: #FC766A;
        
        transition: transform .45s ease, background .45s ease;
      cursor:pointer;
      transform: scale(1.1);
        
        }
    

#buttontry{
    width:23rem
}
#output{
  max-height: 100%;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto 
}
#avatar2{
    position: absolute;
    text-align: center;
    padding: 0.5em;
    border-radius: 1em;
    background-color: white
    
}
#avatar{
    display:none;
}