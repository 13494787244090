.NavbarText{
    font-size:22px;
    display:inline;
    cursor: pointer;
}
.bottomanimation::after {
    content: "";
    display: table;
    width: 0px;
    height: 3px;
    background:rgb(240, 113, 120);
    transition: width .4s;
    -webkit-transition: width .4s;
    -moz-transition: width .4s;
     -ms-transition: width .4s;
      -o-transition: width .4s;

}
/* .NavbarText:hover{
    border-bottom: ;

} */
.navbaricon{
    font-size: 1.7rem;
    padding-top: 3px;
    margin-left: 36px;
    width: 36px;

    

}

.Dropdown {
    display: none;
    position: absolute;
    left:7%;
    top:5.2vh;
    font-size: 1.1rem;
    background-color:#101820;
    padding:20px;
    border: 2px solid #FC766A;
    border-radius: 20px;
  }
.Dropdownitem{
    cursor: pointer;
}
.dropdownicon{
    font-size: 1.6rem;
    margin-right: 15px;
    

}
.iconspin:hover{
    transform-origin: center center;
    animation: spinpop 1600ms ease-in-out infinite;
    cursor: pointer;


}
@keyframes spinpop{
    from{
       transform: rotate(0deg); 
    }
    to{
        transform: rotate(360deg); 
     }

}
.bottomanimation::after+ .WorkDrop {
    display: block;
  }
.WorkDrop:hover {
    display: block;
  }      
.Work:hover + .WorkDrop {
    display: block;
  }
.NavbarText:hover::after {
    width: 99%;

}
.NavbarBrand{
    height: 80%;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* margin-right: 30px;
    margin-bottom: 3px; */
    cursor: pointer;
}
.Navbar{
    
    height: 7vh;
    background: #101820;
    position: fixed;
    width: 100%;
    display: flex;
    z-index: 1000


  }
.Nav{
    display: flex;
    justify-content: flex-start;
    padding-top: 18px;
    padding-bottom: 18px;

    
}
.right{
    float:  right;
    width: 25%;
    display: flex;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 10%;
}
.left{
    float:  left;
    padding-left: 10%;
    padding-right: 5%;
    width: 25%;
    display: flex;
    justify-content: space-between;
    
    
}
.spacer{
    width:10%;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5%;
    padding-right: 5%;
}
/* Underline From Center */
.hvr-underline-from-center {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
  }
  .hvr-underline-from-center:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: #FC766A;
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
    left: 0;
    right: 0;
  }
