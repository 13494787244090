.Page {
  padding-top: 8%;
}
.ConTitle {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-size: 60px;
  display: block;
  margin-bottom: 1em;
  text-align: center;
  color: #f07178;
}
.ContactCard {
  width: 86%;
  margin-top: 50px;

  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  background-color: rgba(67, 71, 88, 1);
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  box-sizing: border-box;
  color: #f07178;
  cursor: pointer;
  text-decoration: none;
  display: block;
  transition: all 0.4s ease-in-out;
  padding: 3em 3em 4em;
}

/* Style inputs with type="text", select elements and textareas */
input[type=text], select, textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 0px solid #ccc; /* Gray border */
  border-bottom: 1px solid #f07178;
  color: #ccc;
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
  background-color: transparent;
  outline: none;
}
option { 
  /* Whatever color  you want */
  background-color: rgb(136, 136, 136);
  color: white;
  border: 0px;
}
/* Style the submit button with a specific background color etc */
input[type=submit] {
  background-color: #f07178;
  margin-left: auto;
  margin-right: auto;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
  background-color: #ccc;
}

/* Add a background color and some padding around the form */
.container {
  padding: 20px;
  padding-top: 0px!important;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccc;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #ccc;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
  /* background: black; */
 -webkit-box-shadow: 0 0 0 30px black  inset !important;
 -webkit-text-fill-color: #ccc !important;
}
.data-card-large {
  width:80vw;
 
  color:white!important;
  max-width: 100em;
  min-height: 20.75em;
  overflow: hidden;
  border-radius: .5em;
  text-decoration: none;
  background: #0f171f;
  margin: 1em;
  margin-inline: 1.5EM;
  padding: 2.75em 2.5em;
  box-shadow: 0 1.5em 2.5em -.5em rgba(#000000, .1);
  transition: width 1s ease-in-out;

}
.data-card-large h3{

    color:white!important;

  
  }
  .data-card-large h4{

      color:white!important;

    
    }

  
.hide{
  display:none;

}

.padd{
  margin-bottom: 0px!important;
}

#cwr{
  justify-content: center;
  display:flex;
  margin-top: 10%;
  margin-bottom: 10%;
}
.cwb{
  padding-bottom: 6vh!important;
}
.uhd{
  display: none;
}
.hd{
  display:block !important;
  z-index:    10;
  top:        0; 
  left:       0; 
  position:   fixed; 
width:80%!important;
  transition: all 1s;
}
@keyframes myAnim {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}
  100% {
		transform: scale(1);
	}
}
.link-text:hover {
  color: #f07178;
  
  transition: transform .45s ease, background .45s ease;
cursor:pointer;
transform: scale(1.02);
  
  }
.hideanim{
    animation: ease-in-out move-in-steps 1050ms;
  }
.hideanim2{ 
    animation: ease-in-out move-in-steps2 2s;
  }
.showanim{
    animation: ease-in-out move-in-steps-right 2s;
  }
.showanim2{ 
    animation: ease-in-out move-in-steps-right2 1050ms;
  }
.abs {
  position:absolute;
  bottom:50vw
}
@keyframes move-in-steps {
  0% {
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity:1;

  }
  100% {
    -moz-transform: translate(100vw, 0);
    -ms-transform: translate(100vw, 0);
    -o-transform: translate(100vw, 0);
    -webkit-transform: translate(100vw, 0);
    transform: translate(100vw, 0);
    opacity:0;

  }
}
@keyframes move-in-steps2 {
  0% {
    -moz-transform: translate(-100vw, 0);
    -ms-transform: translate(-100vw, 0);
    -o-transform: translate(-100vw, 0);
    -webkit-transform: translate(-100vw, 0);
    transform: translate(-100vw, 0);

    opacity:0;

  }
  100% {
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity:1;


  }
}
@keyframes move-in-steps-right {
  0% {
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity:1;

  }
  100% {
    -moz-transform: translate(-100vw, 0);
    -ms-transform: translate(-100vw, 0);
    -o-transform: translate(-100vw, 0);
    -webkit-transform: translate(-100vw, 0);
    transform: translate(-100vw, 0);
    opacity:0;

  }
}
@keyframes move-in-steps-right2 {
  0% {    -moz-transform: translate(100vw, 0);
    -ms-transform: translate(100vw, 0);
    -o-transform: translate(100vw, 0);
    -webkit-transform: translate(100vw, 0);
    transform: translate(100vw, 0);


    opacity:0;

  }
  100% {
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity:1;


  }
}
.close {
  width:4rem;
  height:4rem;
  position: absolute;
  top: 4rem;
  left: 2rem;
  cursor: pointer;
}
.close:hover {
  animation: myAnim 0.8s infinite ease-in-out;

}

.ContentCard{
  margin-bottom: 4.5rem!important;
}
