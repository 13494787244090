.Page {
  padding-top: 8%;
}

.WorkDiv {
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  margin: 0 auto;
  transition: all 0.2s ease-in-out;
  max-width: 1330px;
  padding: 14em 0 8em;
}

.WorkTitle {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  margin: 0.67em 0;
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 120%;
  font-size: 86.4px;
  font-weight: bold;
  transform: translateY(0px);
}

.WorkBreakdown {
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  opacity: 1;
  transform: translateY(0px);
}