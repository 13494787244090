@import url(https://fonts.googleapis.com/css2?family=Varela&display=swap);
body {
  background-color: #101820;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: 'Varela', sans-serif; */
  color: #d0d0d0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    
      overflow-x: hidden;
    
}
a {text-decoration: none;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.NavbarText{
    font-size:22px;
    display:inline;
    cursor: pointer;
}
.bottomanimation::after {
    content: "";
    display: table;
    width: 0px;
    height: 3px;
    background:rgb(240, 113, 120);
    transition: width .4s;
    -webkit-transition: width .4s;
    -moz-transition: width .4s;
     -ms-transition: width .4s;
      -o-transition: width .4s;

}
/* .NavbarText:hover{
    border-bottom: ;

} */
.navbaricon{
    font-size: 1.7rem;
    padding-top: 3px;
    margin-left: 36px;
    width: 36px;

    

}

.Dropdown {
    display: none;
    position: absolute;
    left:7%;
    top:5.2vh;
    font-size: 1.1rem;
    background-color:#101820;
    padding:20px;
    border: 2px solid #FC766A;
    border-radius: 20px;
  }
.Dropdownitem{
    cursor: pointer;
}
.dropdownicon{
    font-size: 1.6rem;
    margin-right: 15px;
    

}
.iconspin:hover{
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation: spinpop 1600ms ease-in-out infinite;
            animation: spinpop 1600ms ease-in-out infinite;
    cursor: pointer;


}
@-webkit-keyframes spinpop{
    from{
       -webkit-transform: rotate(0deg);
               transform: rotate(0deg); 
    }
    to{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg); 
     }

}
@keyframes spinpop{
    from{
       -webkit-transform: rotate(0deg);
               transform: rotate(0deg); 
    }
    to{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg); 
     }

}
.bottomanimation::after+ .WorkDrop {
    display: block;
  }
.WorkDrop:hover {
    display: block;
  }      
.Work:hover + .WorkDrop {
    display: block;
  }
.NavbarText:hover::after {
    width: 99%;

}
.NavbarBrand{
    height: 80%;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* margin-right: 30px;
    margin-bottom: 3px; */
    cursor: pointer;
}
.Navbar{
    
    height: 7vh;
    background: #101820;
    position: fixed;
    width: 100%;
    display: flex;
    z-index: 1000


  }
.Nav{
    display: flex;
    justify-content: flex-start;
    padding-top: 18px;
    padding-bottom: 18px;

    
}
.right{
    float:  right;
    width: 25%;
    display: flex;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 10%;
}
.left{
    float:  left;
    padding-left: 10%;
    padding-right: 5%;
    width: 25%;
    display: flex;
    justify-content: space-between;
    
    
}
.spacer{
    width:10%;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5%;
    padding-right: 5%;
}
/* Underline From Center */
.hvr-underline-from-center {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
  }
  .hvr-underline-from-center:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: #FC766A;
    height: 4px;
    transition-property: left, right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  .hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
    left: 0;
    right: 0;
  }


.home{
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  height: 100vh;
}
#Greeting {
  font-size: 2.7rem;
  padding-top:200px
}

#GreetingCompound {
  display: flex;
  justify-content: space-evenly;
  height: 95vh;
  
}

#GreetingText {
  margin: 0px;
}

#GreetingSubText {
  font-size: 34px;
}

#GreetingPicDiv {
  width:50vw;
  height:700px;
  display: flex;

}

#GreetingPic {
  border: 3px solid #FC766A;
  border-radius: 10px;
  -webkit-filter: drop-shadow(0px 52px 74px rgba(0, 21, 64, 0.138559));
          filter: drop-shadow(0px 52px 74px rgba(0, 21, 64, 0.138559));
}

.ContentCard {
  height: 80vh!important;
  width: 80%;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2rem;
  background-color: #0b1116!important;
  /* border: 3px solid black; */
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  box-sizing: border-box;
  color: #FC766A;
  cursor: default !important;
  text-decoration: none;
  display: block;
  transition: all 0.4s ease-in-out;
  padding: 3em 3em 4em;
position: relative;
  box-shadow: 0 52px 74px rgba(0,0,0,.12);
  background: #191919;
  /* box-shadow: 0 0 3vw #a14c50; */
}
.LastCard {
  margin-bottom: 100px;
}

.CCategory {
  -webkit-tap-highlight-color: transparent;

  -webkit-font-smoothing: antialiased;
  display: block;
  font-size: 1.2em;
  opacity: 0.6;
  letter-spacing: 1.5px;
  font-weight: bold;
  text-align: right;
  color: #d0d0d0;
  text-decoration: none;
  margin-bottom: 0em;
  margin-top: 0em;
}

.CTitle {
  -webkit-tap-highlight-color: transparent;

  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  margin-top: 0;
  font-weight: 600;
  font-size: 3.5em;
  display: block;
  margin-bottom: .5em;
  text-align: right;
  color: #FC766A;
  text-transform: lowercase;
}
.CPicDiv{
  text-align: center;
  transition: width 2s linear 1s;
  margin-bottom: 60px;
}
.Cpic{
  
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  /* border: 2px solid #292d3e; */
  
  
  -webkit-font-smoothing: antialiased;
  border-style: none;
  vertical-align: middle;
  -webkit-filter: drop-shadow(0px 52px 74px rgba(1, 12, 34, 0.139));
          filter: drop-shadow(0px 52px 74px rgba(1, 12, 34, 0.139));


}
.Cpic1{
  
  border-radius: 10px;
position: absolute;
right:30%

}

.row{
  position: relative;
  top: 28.5%;
  float:left;
  padding-right: 160px;
  width: 50px;
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  height: 300px;

}

.circle {
  transition: all 1s ease-in-out!important;
  border-radius: 50%;
  background-color: rgb(240, 112, 118);
  width:50px;
  display: block;
  height:50px;
  -webkit-animation-name: scale-up-blob ;
          animation-name: scale-up-blob ;
  transition: all 1s ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}
.row1{
  top: 14.3%;
  height:500px!important
}
.row2{
  top: 0%;
  height:700px!important
}
.row3{
  padding-right: 0px;
}

/* .ContentCard:hover{
  transform: translate3d(0,-5px,0);
} */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}
@-webkit-keyframes scale-up-blob {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-blob {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.Page {
  padding-top: 8%;
}

.WorkDiv {
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  margin: 0 auto;
  transition: all 0.2s ease-in-out;
  max-width: 1330px;
  padding: 14em 0 8em;
}

.WorkTitle {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  margin: 0.67em 0;
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 120%;
  font-size: 86.4px;
  font-weight: bold;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.WorkBreakdown {
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}
.Page {
  padding-top: 8%;
}
#Greeting {
  font-size: 2.7rem;
  padding-top:200px
}

#GreetingCompound {
  display: flex;
  justify-content: space-evenly;
  height: 95vh;
  
}

#GreetingText {
  margin: 0px;
}

#GreetingSubText {
  font-size: 34px;
}

#GreetingPicDiv {
  width:50vw;
  height:700px;
  display: flex;

}

#GreetingPic {
  border: 3px solid #f07178;
  border-radius: 10px;
  -webkit-filter: drop-shadow(0px 52px 74px rgba(0, 21, 64, 0.138559));
          filter: drop-shadow(0px 52px 74px rgba(0, 21, 64, 0.138559));
}
.question {
  height: 30vh!important;
  width: 20%;
  margin-top: 200px;
  margin-left: auto;
  margin-right: 14%;
  border-radius: 20px;
  background-color: rgb(36, 36, 36);
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  box-sizing: border-box;
  color: #f07178;
  cursor: pointer;
  text-decoration: none;
  display: block;
  transition: all 0.4s ease-in-out;
  padding: 1em 1em 1em;
  float:left;
  border-style: solid;
  border-color: #f07178;
  height: 10vh!important;
  width: 10vh;
  border-radius: 50px;
  cursor: pointer;
  border-width: thin
}
.unshrink{
  height: 30vh!important;
  width: 17%;
  cursor:auto;
  /* //border-color: transparent; */
}
.unhide{
  /* transition: all 1s ease-in-out!important; */
  visibility: visible !important;
  transition-delay:0.4s!important


}
.wraptext{
  transition-delay:0s;
  visibility: hidden;

}
.number{
  display:none;
  visibility: hidden;
}
.numbershow{
  visibility: visible !important;
  display: block!important;
  position: relative!important;
  top: -10%;
  left: 32%;
}
.cicle{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #f07178;
  display: block;
  /* animation-name: scale-up-blob ;
  transition: all 1s ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 2s; */
}
select{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New'!important
}
input{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New'!important
}

/* Style inputs with type="text", select elements and textareas */
input[type=text], select, textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 0px solid #ccc; /* Gray border */
  border-bottom: 1px solid #f07178;
  color: #ccc;
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
  background-color: transparent;
  outline: none;
}
option { 
  /* Whatever color  you want */
  background-color: rgb(136, 136, 136);
  color: white;
  border: 0px;
}
/* Style the submit button with a specific background color etc */
input[type=submit] {
  background-color: #f07178;
  margin-left: auto;
  margin-right: auto;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
  background-color: #ccc;
}

/* Add a background color and some padding around the form */
.container {
  padding: 20px;
  padding-top: 0px!important;
}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccc;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #ccc;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
  /* background: black; */
 -webkit-box-shadow: 0 0 0 30px black  inset !important;
 -webkit-text-fill-color: #ccc !important;
}
.Page {
  padding-top: 8%;
}

#Greeting {
  font-size: 2.7rem;
  padding-top: 200px
}

#GreetingCompound {
  display: flex;
  justify-content: space-evenly;
  height: 95vh;

}

#GreetingText {
  margin: 0px;
}

#GreetingSubText {
  font-size: 34px;
}

#GreetingPicDiv {
  width: 50vw;
  height: 700px;
  display: flex;

}

#GreetingPic {
  border: 3px solid #f07178;
  border-radius: 10px;
  -webkit-filter: drop-shadow(0px 52px 74px rgba(0, 21, 64, 0.138559));
          filter: drop-shadow(0px 52px 74px rgba(0, 21, 64, 0.138559));
}

.question {
  height: 30vh !important;
  width: 20%;
  margin-top: 200px;
  margin-left: auto;
  margin-right: 14%;
  border-radius: 20px;
  background-color: rgb(36, 36, 36);
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  box-sizing: border-box;
  color: #f07178;
  cursor: pointer;
  text-decoration: none;
  display: block;
  transition: all 0.4s ease-in-out;
  padding: 1em 1em 1em;
  float: left;
  border-style: solid;
  border-color: #f07178;
  height: 10vh !important;
  width: 10vh;
  border-radius: 50px;
  cursor: pointer;
  border-width: thin
}

.unshrink {
  height: 30vh !important;
  width: 17%;
  cursor: auto;
  /* //border-color: transparent; */
}

.unhide {
  /* transition: all 1s ease-in-out!important; */
  visibility: visible !important;
  transition-delay: 0.4s !important
}

.wraptext {
  transition-delay: 0s;
  visibility: hidden;

}

.number {
  display: none;
  visibility: hidden;
}

.numbershow {
  visibility: visible !important;
  display: block !important;
  position: relative !important;
  top: -10%;
  left: 32%;
}

.cicle {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #f07178;
  display: block;
  /* animation-name: scale-up-blob ;
  transition: all 1s ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 2s; */
}

select {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New' !important
}

input {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New' !important
}

/* Style inputs with type="text", select elements and textareas */
input[type=text],
select,
textarea {
  width: 100%;
  /* Full width */
  padding: 12px;
  /* Some padding */
  border: 0px solid #ccc;
  /* Gray border */
  border-bottom: 1px solid #f07178;
  color: #ccc;
  box-sizing: border-box;
  /* Make sure that padding and width stays in place */
  margin-top: 6px;
  /* Add a top margin */
  margin-bottom: 16px;
  /* Bottom margin */
  resize: vertical;
  /* Allow the user to vertically resize the textarea (not horizontally) */
  background-color: transparent;
  outline: none;
}

option {
  /* Whatever color  you want */
  background-color: rgb(136, 136, 136);
  color: white;
  border: 0px;
}

/* Style the submit button with a specific background color etc */
input[type=submit] {
  background-color: #f07178;
  margin-left: auto;
  margin-right: auto;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
  background-color: #ccc;
}

/* Add a background color and some padding around the form */
.container {
  padding: 20px;
  padding-top: 0px !important;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1;
  /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccc;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ccc;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /* background: black; */
  -webkit-box-shadow: 0 0 0 30px black inset !important;
  -webkit-text-fill-color: #ccc !important;
}

.Arr {

  width: 11rem;
  cursor: pointer;
  margin: auto;
  display: block;
  margin-top: 9rem;
}

.bouncetop:hover {
  -webkit-animation: myAnim 0.8s infinite ease-in-out;
          animation: myAnim 0.8s infinite ease-in-out;
  cursor: pointer;
}


@-webkit-keyframes myAnim {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


@keyframes myAnim {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.page-contain {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;

}

.data-card {
  width: 25%;
  display: flex;
  flex-direction: column;
  max-width: 20.75em;
  min-height: 20.75em;
  overflow: hidden;
  border-radius: .5em;
  text-decoration: none;
  background: white;
  margin: 1em;
  margin-inline: 1.5EM;
  padding: 2.75em 2.5em;
  box-shadow: 0 1.5em 2.5em -.5em rgba(#000000, .1);
  transition: background .45s ease, -webkit-transform .45s ease;
  transition: transform .45s ease, background .45s ease;
  transition: transform .45s ease, background .45s ease, -webkit-transform .45s ease;
}

.data-card h3 {
  color: #2E3C40;
  font-size: 3.5em;
  font-weight: 600;
  line-height: 1;
  padding-bottom: .5em;
  margin: 0 0 0.142857143em;
  border-bottom: 2px solid #753BBD;
  transition: color .45s ease, border .45s ease;
}

.data-card h4 {
  color: #627084;
  text-transform: uppercase;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
  margin: 0 0 1.777777778em;
  transition: color .45s ease;
}

.data-card p {
  opacity: 0;
  color: #FFFFFF;
  font-weight: 600;
  line-height: 1.8;
  margin: 0 0 1.25em;
  -webkit-transform: translateY(-1em);
          transform: translateY(-1em);
  transition: opacity .45s ease, -webkit-transform .5s ease;
  transition: opacity .45s ease, transform .5s ease;
  transition: opacity .45s ease, transform .5s ease, -webkit-transform .5s ease;
}

.link-text {
  display: block;
  color: #f07178;
  font-size: 1.125em;
  font-weight: 600;
  line-height: 1.2;
  margin: auto 0 0;
  transition: color .45s ease;
}

.link-text svg {
  margin-left: .5em;
  transition: -webkit-transform .6s ease;
  transition: transform .6s ease;
  transition: transform .6s ease, -webkit-transform .6s ease;


}

.link-text path {
  transition: fill .45s ease;
}


.data-card:hover {
  background: #FC766A;
  -webkit-transform: scale(1.02);
          transform: scale(1.02);


}

.data-card:hover h3 {
  color: #FFFFFF;
  border-bottom-color: #A754C4;
}

.data-card:hover h4 {
  color: #FFFFFF;
}

.data-card:hover p {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
}

.link-text:hover {
  color: #FFFFFF;


}

.link-text:hover svg {
  -webkit-animation: point 1.25s infinite alternate;
          animation: point 1.25s infinite alternate;

}

.link-text:hover path {
  fill: #FFFFFF;
}

@-webkit-keyframes point {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(.125em);
            transform: translateX(.125em);
  }
}

@keyframes point {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(.125em);
            transform: translateX(.125em);
  }
}
.Page {
    padding-top: 7vh!important;
    height: 100% ;
    overflow: "hidden"!important
  }
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
    border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #888;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.Page {
  padding-top: 8%;
}

.WorkDiv {
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  margin: 0 auto;
  transition: all 0.2s ease-in-out;
  max-width: 1330px;
  padding: 14em 0 8em;
}

.WorkTitle {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  margin: 0.67em 0;
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 120%;
  font-size: 86.4px;
  font-weight: bold;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.WorkBreakdown {
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}
.Page {
  padding-top: 8%;
}
#Greeting {
  font-size: 2.7rem;
  padding-top:200px
}

#GreetingCompound {
  display: flex;
  justify-content: space-evenly;
  height: 95vh;
  
}

#GreetingText {
  margin: 0px;
}

#GreetingSubText {
  font-size: 34px;
}

#GreetingPicDiv {
  width:50vw;
  height:700px;
  display: flex;

}

#GreetingPic {
  border: 3px solid #f07178;
  border-radius: 10px;
  -webkit-filter: drop-shadow(0px 52px 74px rgba(0, 21, 64, 0.138559));
          filter: drop-shadow(0px 52px 74px rgba(0, 21, 64, 0.138559));
}
.question {
  height: 30vh!important;
  width: 20%;
  margin-top: 200px;
  margin-left: auto;
  margin-right: 14%;
  border-radius: 20px;
  background-color: rgb(36, 36, 36);
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  box-sizing: border-box;
  color: #f07178;
  cursor: pointer;
  text-decoration: none;
  display: block;
  transition: all 0.4s ease-in-out;
  padding: 1em 1em 1em;
  float:left;
  border-style: solid;
  border-color: #f07178;
  height: 10vh!important;
  width: 10vh;
  border-radius: 50px;
  cursor: pointer;
  border-width: thin
}
.unshrink{
  height: 30vh!important;
  width: 17%;
  cursor:auto;
  /* //border-color: transparent; */
}
.unhide{
  /* transition: all 1s ease-in-out!important; */
  visibility: visible !important;
  transition-delay:0.4s!important


}
.wraptext{
  transition-delay:0s;
  visibility: hidden;

}
.number{
  display:none;
  visibility: hidden;
}
.numbershow{
  visibility: visible !important;
  display: block!important;
  position: relative!important;
  top: -10%;
  left: 32%;
}
.cicle{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #f07178;
  display: block;
  /* animation-name: scale-up-blob ;
  transition: all 1s ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 2s; */
}
select{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New'!important
}
input{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New'!important
}

/* Style inputs with type="text", select elements and textareas */
input[type=text], select, textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 0px solid #ccc; /* Gray border */
  border-bottom: 1px solid #f07178;
  color: #ccc;
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
  background-color: transparent;
  outline: none;
}
option { 
  /* Whatever color  you want */
  background-color: rgb(136, 136, 136);
  color: white;
  border: 0px;
}
/* Style the submit button with a specific background color etc */
input[type=submit] {
  background-color: #f07178;
  margin-left: auto;
  margin-right: auto;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
  background-color: #ccc;
}

/* Add a background color and some padding around the form */
.container {
  padding: 20px;
  padding-top: 0px!important;
}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccc;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #ccc;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
  /* background: black; */
 -webkit-box-shadow: 0 0 0 30px black  inset !important;
 -webkit-text-fill-color: #ccc !important;
}
#special{
  padding: 0%;
  overflow: hidden;
}
#wrapper{
  width:100%;
  height: 100%;
  
}
#left{    
  float:left; 
  width:50%;
  height: 100%;
  background-color: 	#FC766A;
  padding:2rem;
  box-sizing: border-box;

}
#right{
  width:50%;
  height: 100%;
  padding:2rem;
  box-sizing: border-box;
  background-color: 	whitesmoke;
  float: left;
}
#con{
  width: 100%!important;
  }
#tt{
  padding-bottom: 1rem!important;
}
#pc{
  padding-bottom: 4rem!important;
}
#wrapper { display: flex; }
.icon{
  width:10%;
  margin-bottom:1.7rem;
  margin-right:10% ;
  float: left
}
.lbl{
  width:80%;
  height:2rem;
  float: left;
  margin-bottom:1.7rem
}
.Page {
  padding-top: 8%;
}
.ConTitle {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-size: 60px;
  display: block;
  margin-bottom: 1em;
  text-align: center;
  color: #f07178;
}
.ContactCard {
  width: 86%;
  margin-top: 50px;

  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  background-color: rgba(67, 71, 88, 1);
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  box-sizing: border-box;
  color: #f07178;
  cursor: pointer;
  text-decoration: none;
  display: block;
  transition: all 0.4s ease-in-out;
  padding: 3em 3em 4em;
}

/* Style inputs with type="text", select elements and textareas */
input[type=text], select, textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 0px solid #ccc; /* Gray border */
  border-bottom: 1px solid #f07178;
  color: #ccc;
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
  background-color: transparent;
  outline: none;
}
option { 
  /* Whatever color  you want */
  background-color: rgb(136, 136, 136);
  color: white;
  border: 0px;
}
/* Style the submit button with a specific background color etc */
input[type=submit] {
  background-color: #f07178;
  margin-left: auto;
  margin-right: auto;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
  background-color: #ccc;
}

/* Add a background color and some padding around the form */
.container {
  padding: 20px;
  padding-top: 0px!important;
}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccc;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #ccc;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
  /* background: black; */
 -webkit-box-shadow: 0 0 0 30px black  inset !important;
 -webkit-text-fill-color: #ccc !important;
}
.data-card-large {
  width:80vw;
 
  color:white!important;
  max-width: 100em;
  min-height: 20.75em;
  overflow: hidden;
  border-radius: .5em;
  text-decoration: none;
  background: #0f171f;
  margin: 1em;
  margin-inline: 1.5EM;
  padding: 2.75em 2.5em;
  box-shadow: 0 1.5em 2.5em -.5em rgba(#000000, .1);
  transition: width 1s ease-in-out;

}
.data-card-large h3{

    color:white!important;

  
  }
  .data-card-large h4{

      color:white!important;

    
    }

  
.hide{
  display:none;

}

.padd{
  margin-bottom: 0px!important;
}

#cwr{
  justify-content: center;
  display:flex;
  margin-top: 10%;
  margin-bottom: 10%;
}
.cwb{
  padding-bottom: 6vh!important;
}
.uhd{
  display: none;
}
.hd{
  display:block !important;
  z-index:    10;
  top:        0; 
  left:       0; 
  position:   fixed; 
width:80%!important;
  transition: all 1s;
}
@-webkit-keyframes myAnim {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	50% {
		-webkit-transform: scale(1.1);
		        transform: scale(1.1);
	}
  100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}
@keyframes myAnim {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	50% {
		-webkit-transform: scale(1.1);
		        transform: scale(1.1);
	}
  100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}
.link-text:hover {
  color: #f07178;
  
  transition: background .45s ease, -webkit-transform .45s ease;
  
  transition: transform .45s ease, background .45s ease;
  
  transition: transform .45s ease, background .45s ease, -webkit-transform .45s ease;
cursor:pointer;
-webkit-transform: scale(1.02);
        transform: scale(1.02);
  
  }
.hideanim{
    -webkit-animation: ease-in-out move-in-steps 1050ms;
            animation: ease-in-out move-in-steps 1050ms;
  }
.hideanim2{ 
    -webkit-animation: ease-in-out move-in-steps2 2s; 
            animation: ease-in-out move-in-steps2 2s;
  }
.showanim{
    -webkit-animation: ease-in-out move-in-steps-right 2s;
            animation: ease-in-out move-in-steps-right 2s;
  }
.showanim2{ 
    -webkit-animation: ease-in-out move-in-steps-right2 1050ms; 
            animation: ease-in-out move-in-steps-right2 1050ms;
  }
.abs {
  position:absolute;
  bottom:50vw
}
@-webkit-keyframes move-in-steps {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity:1;

  }
  100% {
    -webkit-transform: translate(100vw, 0);
    transform: translate(100vw, 0);
    opacity:0;

  }
}
@keyframes move-in-steps {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity:1;

  }
  100% {
    -webkit-transform: translate(100vw, 0);
    transform: translate(100vw, 0);
    opacity:0;

  }
}
@-webkit-keyframes move-in-steps2 {
  0% {
    -webkit-transform: translate(-100vw, 0);
    transform: translate(-100vw, 0);

    opacity:0;

  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity:1;


  }
}
@keyframes move-in-steps2 {
  0% {
    -webkit-transform: translate(-100vw, 0);
    transform: translate(-100vw, 0);

    opacity:0;

  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity:1;


  }
}
@-webkit-keyframes move-in-steps-right {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity:1;

  }
  100% {
    -webkit-transform: translate(-100vw, 0);
    transform: translate(-100vw, 0);
    opacity:0;

  }
}
@keyframes move-in-steps-right {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity:1;

  }
  100% {
    -webkit-transform: translate(-100vw, 0);
    transform: translate(-100vw, 0);
    opacity:0;

  }
}
@-webkit-keyframes move-in-steps-right2 {
  0% {
    -webkit-transform: translate(100vw, 0);
    transform: translate(100vw, 0);


    opacity:0;

  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity:1;


  }
}
@keyframes move-in-steps-right2 {
  0% {
    -webkit-transform: translate(100vw, 0);
    transform: translate(100vw, 0);


    opacity:0;

  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity:1;


  }
}
.close {
  width:4rem;
  height:4rem;
  position: absolute;
  top: 4rem;
  left: 2rem;
  cursor: pointer;
}
.close:hover {
  -webkit-animation: myAnim 0.8s infinite ease-in-out;
          animation: myAnim 0.8s infinite ease-in-out;

}

.ContentCard{
  margin-bottom: 4.5rem!important;
}

#canvas2 {
    display: inline;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    max-height: 50vh;
    max-width: 75vh;
    position:  absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

}
#canvasDiv{
    float:left;
    width:40%;
    max-width: 70vh;
    height: 50vh!important;
    padding-right: 10%;

    background-color: rgb(242,231,191);
    margin-top: 3vh;
    margin-bottom: 0%;
    border-radius: 1em;
    height:0;
    position: relative;
}
.playgrounddiv{
    width: 100%;

}
#model-selector{
    width: auto;
    height:40vh
}
#controls{
    float:right;
    margin-top: 3vh;
    display :block;
    box-sizing: border-box;
    width:50%;
    padding-left:12rem;

}
.fields{
    margin-bottom: 5%;
}
.btn{
    width: 25%;
    margin-right: 5%;
    height: 45px;
    border-radius: 5%;
    border: 0px;
    background-color: #FC766A;
    color: #ccc;
    cursor: pointer;
}
.btn{
    width: 25%;
    margin-right: 5%;
    height: 45px;
    border-radius: 5%;
    border: 0px;
    background-color: #FC766A;
    color: #ccc;
    cursor: pointer;
}
.false{    -webkit-text-size-adjust: 100%;
    
    font-size: 1.4vh;
    line-height: 120%;
    box-sizing: border-box;
    transition: all .1s ease;
    color: #ccc;
    display: inline-block;
    padding: 1.5vh;
    width: 11rem;
    height: 17vh;
    border-radius: 1EM;
    margin: .5vh;
    margin-right: 2vh;
    margin-top: 2vh;
    text-align: center;
    background-color: #101820;
    float: left;

    cursor:pointer
}
.false:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: all .5s ease;
}
.true{
    -webkit-text-size-adjust: 100%;
    
    font-size: 1.4vh;
    line-height: 120%;
    box-sizing: border-box;
    transition: all .1s ease;
    color: #ccc;
    display: inline-block;
    padding: 1.3vh;
    width: 11rem;
    height: 17vh;
    border-radius: 1EM;
    margin: .5vh;
    margin-right: 2vh;
    margin-top: 2vh;
    text-align: center;
    background-color: #101820;
    float: left;
    border: .4vh solid #FC766A;
    cursor:pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

    
}

.img{
    -webkit-text-size-adjust: 100%;
    
    font-size: 1.4vh;
    line-height: 120%;
    color: #000;
    text-align: center;
    box-sizing: border-box;
    border: 0;
    vertical-align: middle;
    transition: all .1s ease;
    width: 8vh;
}
#brushes{
    -webkit-text-size-adjust: 100%;
    
    color: #333;
    line-height: 100%;
    box-sizing: border-box;
    transition: all .1s ease;
    font-size: 2vh;
}
#brushes div{-webkit-text-size-adjust: 100%;

color: #333;
line-height: 120%;
font-size: 2vh;
box-sizing: border-box;
transition: all .1s ease;
display: inline-block;
vertical-align: middle;
margin-right: 1vw;

}

#brushes label{-webkit-text-size-adjust: 100%;

line-height: 120%;
font-size: 2vh;
display: inline-block;
max-width: 100%;
font-weight: 700;
transition: all .1s ease;
box-sizing: border-box;
color: #fff;
width: 4vh;
height: 4vh;
margin: 1vh;
border-radius: 50%;
border: .2vh solid transparent;
cursor:pointer;
padding:1.2vh;
}
#brushes div #br-1~label {
    background-color: blue;
}
#brushes div #br-2~label {
    background-color: red;
}
#brushes div #br--3~label {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAN1wAADdcBQiibeAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAHfSURBVHic7ds9bgJBDIZhJ23IIeESKZMyt4oUQDmWU4AlhIZlfmyPPfpeidrwaBDa9UKEEEIIIYQQQgghhBaLifZMtHOct2Oivdc805joi4mYic5M9O4w742Jfq4zv63nmXaDxx6Id3icGrGAZ4r4AC8n4gaeCeITvFyIFXiqiJV4ORAb8FQQG/FiI3bgDSF24sVEHMDrQhzEi4WogNeEqIQXA1ERrwpRGW8uIhMdlD+IvI5cuOzjy+XZ0WjmYQagxWkonkTjWaetU58ecVk8J8Q/JvpdFk8yRlwbT0qEGA9PSoAYF08KjBgfTwqImAdPCoSYD08KgJgXT5qImB9PmoC4Dp7kiLgennRFtLo0Y75c+rnivXoOc+hl9hswy/Er7PIEhGsTfkTWQZyAtw7iRLz8iAHw8iIGwsuHGBAvD2JgvPiICfDiIibCi4dojGe51pyPaIx3YvvF+jxEDzynWXMQGQ8XjcdEH5YnrzDP4iR+epqVPpQWYtWdZGXEuXiSAmLTbXglxBh40gBi1w5jEDEWntSBOLQA6kSMiSc1IKpszxoRY+NJFYiqq8dKxBx40gaiyd72CWIuPKmAaLr0foCYE0+6QXR5YuAOMTeexPjLP0IIIYQQQgghhBBS6R/rQIQOR9dRzwAAAABJRU5ErkJggg==) no-repeat 50%;
    background-size: cover;
}
#brushes div input:checked~label {
    border: 0.4vh solid white;
}
#brushes div #br-2~label:hover {
    background-color: #fd7070;
}
#brushes div #br-1~label:hover {
    background-color: #70edfd;
}
#brushes div #br--3~label:hover {
    border: 0.4vh solid white;
    padding: 0.8vh;
}
#brushes input{-webkit-text-size-adjust: 100%;

font: inherit;
color: inherit;
font-family: inherit;
font-size: inherit;
transition: all .1s ease;
box-sizing: border-box;
padding: 0;
margin: 4px 0 0;
line-height: normal;
display: none;
}
#clearAll{
    margin: 0;

    height: 4vh;
    box-sizing: border-box;
    width: 10vh;
    padding: 1.2vh;
    background-color: #fff;
    color: #FC766A;
    border-radius: 1.3em;
    border:0;
    font-weight: 600;
}
#clearAll:hover{
    
        color: #FC766A;
        
        transition: background .45s ease, -webkit-transform .45s ease;
        
        transition: transform .45s ease, background .45s ease;
        
        transition: transform .45s ease, background .45s ease, -webkit-transform .45s ease;
      cursor:pointer;
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
        
        }
    

#buttontry{
    width:23rem
}

#canvas2 {
    display: inline;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    max-height: 50vh;
    max-width: 75vh;
    position:  absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

}
#canvasDiv{
    float:left;
    width:40%;
    max-width: 70vh;
    height: 50vh!important;
    padding-right: 10%;

    background-color: rgb(242,231,191);
    margin-top: 3vh;
    margin-bottom: 0%;
    border-radius: 1em;
    height:0;
    position: relative;
}
.playgrounddiv{
    width: 100%;

}
#model-selector{
    width: auto;
    height:40vh
}
#controls{
    float:right;
    margin-top: 3vh;
    display :block;
    box-sizing: border-box;
    width:50%;
    padding-left:12rem;

}
.fields{
    margin-bottom: 5%;
}
.btn{
    width: 25%;
    margin-right: 5%;
    height: 45px;
    border-radius: 5%;
    border: 0px;
    background-color: #FC766A;
    color: #ccc;
    cursor: pointer;
}
.btn{
    width: 25%;
    margin-right: 5%;
    height: 45px;
    border-radius: 5%;
    border: 0px;
    background-color: #FC766A;
    color: #ccc;
    cursor: pointer;
}
.false{    -webkit-text-size-adjust: 100%;
    
    font-size: 1.4vh;
    line-height: 120%;
    box-sizing: border-box;
    transition: all .1s ease;
    color: #ccc;
    display: inline-block;
    padding: 1.5vh;
    width: 11rem;
    height: 17vh;
    border-radius: 1EM;
    margin: .5vh;
    margin-right: 2vh;
    margin-top: 2vh;
    text-align: center;
    background-color: #101820;
    float: left;

    cursor:pointer
}
.false:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: all .5s ease;
}
.true{
    -webkit-text-size-adjust: 100%;
    
    font-size: 1.4vh;
    line-height: 120%;
    box-sizing: border-box;
    transition: all .1s ease;
    color: #ccc;
    display: inline-block;
    padding: 1.3vh;
    width: 11rem;
    height: 17vh;
    border-radius: 1EM;
    margin: .5vh;
    margin-right: 2vh;
    margin-top: 2vh;
    text-align: center;
    background-color: #101820;
    float: left;
    border: .4vh solid #FC766A;
    cursor:pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

    
}

.img{
    -webkit-text-size-adjust: 100%;
    
    font-size: 1.4vh;
    line-height: 120%;
    color: #000;
    text-align: center;
    box-sizing: border-box;
    border: 0;
    vertical-align: middle;
    transition: all .1s ease;
    width: 8vh;
}
#brushes{
    -webkit-text-size-adjust: 100%;
    
    color: #333;
    line-height: 100%;
    box-sizing: border-box;
    transition: all .1s ease;
    font-size: 2vh;
}
#brushes div{-webkit-text-size-adjust: 100%;

color: #333;
line-height: 120%;
font-size: 2vh;
box-sizing: border-box;
transition: all .1s ease;
display: inline-block;
vertical-align: middle;
margin-right: 1vw;

}

#brushes label{-webkit-text-size-adjust: 100%;

line-height: 120%;
font-size: 2vh;
display: inline-block;
max-width: 100%;
font-weight: 700;
transition: all .1s ease;
box-sizing: border-box;
color: #fff;
width: 4vh;
height: 4vh;
margin: 1vh;
border-radius: 50%;
border: .2vh solid transparent;
cursor:pointer;
padding:1.2vh;
}
#brushes div #br-1~label {
    background-color: blue;
}
#brushes div #br-2~label {
    background-color: red;
}
#brushes div #br--3~label {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAN1wAADdcBQiibeAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAHfSURBVHic7ds9bgJBDIZhJ23IIeESKZMyt4oUQDmWU4AlhIZlfmyPPfpeidrwaBDa9UKEEEIIIYQQQgghhBaLifZMtHOct2Oivdc805joi4mYic5M9O4w742Jfq4zv63nmXaDxx6Id3icGrGAZ4r4AC8n4gaeCeITvFyIFXiqiJV4ORAb8FQQG/FiI3bgDSF24sVEHMDrQhzEi4WogNeEqIQXA1ERrwpRGW8uIhMdlD+IvI5cuOzjy+XZ0WjmYQagxWkonkTjWaetU58ecVk8J8Q/JvpdFk8yRlwbT0qEGA9PSoAYF08KjBgfTwqImAdPCoSYD08KgJgXT5qImB9PmoC4Dp7kiLgennRFtLo0Y75c+rnivXoOc+hl9hswy/Er7PIEhGsTfkTWQZyAtw7iRLz8iAHw8iIGwsuHGBAvD2JgvPiICfDiIibCi4dojGe51pyPaIx3YvvF+jxEDzynWXMQGQ8XjcdEH5YnrzDP4iR+epqVPpQWYtWdZGXEuXiSAmLTbXglxBh40gBi1w5jEDEWntSBOLQA6kSMiSc1IKpszxoRY+NJFYiqq8dKxBx40gaiyd72CWIuPKmAaLr0foCYE0+6QXR5YuAOMTeexPjLP0IIIYQQQgghhBBS6R/rQIQOR9dRzwAAAABJRU5ErkJggg==) no-repeat 50%;
    background-size: cover;
}
#brushes div input:checked~label {
    border: 0.4vh solid white;
}
#brushes div #br-2~label:hover {
    background-color: #fd7070;
}
#brushes div #br-1~label:hover {
    background-color: #70edfd;
}
#brushes div #br--3~label:hover {
    border: 0.4vh solid white;
    padding: 0.8vh;
}
#brushes input{-webkit-text-size-adjust: 100%;

font: inherit;
color: inherit;
font-family: inherit;
font-size: inherit;
transition: all .1s ease;
box-sizing: border-box;
padding: 0;
margin: 4px 0 0;
line-height: normal;
display: none;
}
#clearAll{
    margin: 0;

    height: 4vh;
    box-sizing: border-box;
    width: 10vh;
    padding: 1.2vh;
    background-color: #fff;
    color: #FC766A;
    border-radius: 1.3em;
    border:0;
    font-weight: 600;
}
#clearAll:hover{
    
        color: #FC766A;
        
        transition: background .45s ease, -webkit-transform .45s ease;
        
        transition: transform .45s ease, background .45s ease;
        
        transition: transform .45s ease, background .45s ease, -webkit-transform .45s ease;
      cursor:pointer;
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
        
        }
    

#buttontry{
    width:23rem
}
#output{
  max-height: 100%;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto 
}
#avatar2{
    position: absolute;
    text-align: center;
    padding: 0.5em;
    border-radius: 1em;
    background-color: white
    
}
#avatar{
    display:none;
}
